.carousel-item {
  height: auto;
}

.carousel-control-prev,
.carousel-control-next {
  width: 9%;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}

.carousel-indicators [data-bs-target] {
  opacity: 0.2;
}
.carousel-indicators .active {
  opacity: 1 !important;
}

.home-btn {
  z-index: 4;
  position: absolute;
  width: 40px;
  height: 40px;
}
.home-btn > button {
  all: unset;
}

.ba-photos {
  width: 270px;
}
.enlarged-ba-photos{
  width: 618px;
}

.modal-dialog{
  width: 618px;
  height: 618px;
}
.modal-header {
  height: 0px;
  padding: 0;
}

.btn-blue{
  background-color: #0088ab;
}
.btn-slide{
  width: 270px;
}


button.btn-close{
  z-index: 1;
}

.modal{
  --bs-modal-header-border-color: none;
}

#comparison-modal .modal-header button {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbodymap%2Fclose_button.webp?alt=media&token=d0d2b286-f124-4d12-b63c-618c72ac302b") !important;
  background-size: 100% !important;
}

#comparison-modal .btn-close {
  z-index: 2;
  opacity: 100;
  padding: 0;
  width: 2em;
  height: 2em;
  margin-right: calc(-1 * var(--bs-modal-header-padding-x));
}