:root {
  --milan-light-turquoise: #e4f3f3;
  --milan-turquoise: #00a5b4;
  --milan-dark-turquoise: #0088ab;
  --milan-text: #043542;
}

#upsell-calc p {
  color: var(--milan-text);
}

#upsell-calc .h1-small {
  font-size: 1.6rem !important;
}

#upsell-calc .form-control,
#upsell-calc .form-select {
  border-color: #00a5b4;
}
#upsell-calc .form-control:focus,
#upsell-calc .form-select:focus {
  box-shadow: 0 0 0 2px #00a5b4;
  transition: all 0.2s ease-in-out;
}

#upsell-calc .error {
  color: red;
  font-size: smaller;
}

#upsell-calc .form-control.field-error {
  border-color: red !important;
}
#upsell-calc .form-control.field-error:focus {
  box-shadow: 0 0 0 2px red !important;
  /* transition: all 0.2s ease-in-out; */
}

#upsell-calc div.dollar-sign {
  position: relative;
}

#upsell-calc div.dollar-sign input {
  padding-left: 22px;
}

#upsell-calc div.dollar-sign:after {
  position: absolute;
  left: 6px;
  top: 6px;
  content: "$";
  font-size: 1rem;
  color: var(--milan-text);
}

#upsell-calc .width-limit-percent {
  min-width: 520px;
    max-width: 600px;
}

#upsell-calc .hours-input {
  /* width: 280px; */
}

#upsell-calc .hours-input input {
    display: inline;
    width: 80px;
}
#upsell-calc .hours-input label {
    display: inline;
    font-size: 12px;
}

#upsell-calc .row-bg-color-even:nth-child(even){
  background-color: #efefef !important;
}
#upsell-calc .row-bg-color-even:last-child{
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

#upsell-calc .border-bottom-radius:last-child{
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

#upsell-calc .row-bg-color-odd:nth-child(odd){
  background-color: #efefef !important;
}
#upsell-calc .row-bg-color-odd:last-child{
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

#upsell-calc .limit-width {
  max-width: 610px;
}

/* disable arrows for the number input */
#upsell-calc input::-webkit-outer-spin-button,
#upsell-calc input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#upsell-calc input[type=number]{
  -moz-appearance: textfield;
}